<template>
  <div>
    <v-row justify="center">
      <v-col
        :cols="$vuetify.breakpoint.lgAndDown ? 12 : 10"
        class="d-flex justify-center"
        v-resize="onResize"
        ref="svgBalanceContainer"
      >
        <svg :width="width" :height="height" class="svgBalance" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        :cols="$vuetify.breakpoint.lgAndDown ? 12 : 8"
        class="d-flex justify-center mt-10"
      >
        <v-slider
          v-model="slider"
          color="takein"
          :thumb-size="36"
          :min="0"
          :max="15"
          :step="0.1"
          :track-color="'grey'"
          append-icon="mdi-account-supervisor"
          prepend-icon="mdi-silverware"
          thumb-label="always"
        >
          <template v-slot:thumb-label="{ value }">{{ value }}%</template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        :cols="$vuetify.breakpoint.lgAndDown ? 12 : 10"
        class="d-flex justify-space-between"
      >
        <v-alert
          dense
          border="top"
          colored-border
          :color="slider === 0 ? 'primary' : 'lightgrey'"
          :elevation="slider === 0 ? 4 : 1"
          :class="
            'd-flex pa-1 caption text-center mr-2 ' +
              (slider === 0 ? 'active' : 'deactive')
          "
          >{{ $t('Common.alarm_restaurant') }}</v-alert
        >
        <v-alert
          dense
          border="top"
          colored-border
          :color="slider !== 0 && slider !== 15 ? 'warning' : 'lightgrey'"
          :elevation="slider !== 0 && slider !== 15 ? 4 : 1"
          :class="
            'd-flex pa-1 caption text-center ml-4 mr-4 ' +
              (slider !== 0 && slider !== 15 ? 'active' : 'deactive')
          "
          >{{ $t('Common.alarm_vs') }}</v-alert
        >
        <v-alert
          dense
          border="top"
          colored-border
          :color="slider === 15 ? 'takein' : 'lightgrey'"
          :elevation="slider === 15 ? 4 : 1"
          :class="
            'd-flex pa-1 caption text-center ml-2 ' +
              (slider === 15 ? 'active' : 'deactive')
          "
          >{{ $t('Common.alarm_customer') }}</v-alert
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import resize from 'vue-resize-directive'
import * as d3 from 'd3'
import { i18n } from 'fod-core'

export default {
  directives: {
    resize
  },
  components: {},
  data: () => ({
    width: 0,
    height: 200,
    slider: 0,
    maxFee: 15
  }),
  watch: {
    width: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawBalance()
      }
    },
    slider: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawBalance()
      }
    }
  },

  mounted() {
    this.drawBalance()
  },

  methods: {
    onResize() {
      this.width = this.$refs.svgBalanceContainer.clientWidth
    },
    drawBalance() {
      d3.select('.svgBalance')
        .selectAll('*')
        .remove()

      const defs = d3.select('.svgBalance').append('defs')
      const filter = defs.append('filter').attr('id', 'glow')
      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', '1.8')
        .attr('result', 'coloredBlur')
      const feMerge = filter.append('feMerge')
      feMerge.append('feMergeNode').attr('in', 'coloredBlur')
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic')

      this.width = this.$refs.svgBalanceContainer.clientWidth

      const balanceG = d3.select('.svgBalance').append('g')
      const margin = 20,
        barHeight = this.height - 40
      const data = [
        { label: 'Common.restaurant', value: this.slider },
        { label: 'Common.vs', value: this.maxFee },
        { label: 'Common.customer', value: this.maxFee - this.slider }
      ]

      const xScale = d3
        .scaleBand()
        .domain(data.map(d => d.label))
        .range([margin * 0.4, this.width - margin * 2])
        .padding(0.5)

      const xAxis = d3
        .select('.svgBalance')
        .append('g')
        .attr('transform', `translate(0,${this.height - margin})`)
        .call(d3.axisBottom(xScale).tickSize(0))

      xAxis
        .selectAll('text')
        .attr('font-size', '14px')
        .style('fill', '#464d69')
        .attr('font-weight', 'bold')
        .text(d => i18n.t(d))
      xAxis.select('.domain').style('opacity', 0)

      const yScale = d3
        .scaleLinear()
        .domain([0, this.maxFee])
        .range([barHeight, 0])

      const restaurantBarG = balanceG
        .append('g')
        .attr('transform', `translate(${xScale(data[0].label)}, ${margin})`)

      restaurantBarG
        .append('rect')
        .attr('class', 'restaurant-bar')
        .attr('width', xScale.bandwidth())
        .attr('y', yScale(data[0].value))
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('height', barHeight - yScale(data[0].value))
        .style('fill', '#2196F3')
      restaurantBarG
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', yScale(data[0].value) - 2)
        .style('fill', '#464d69')
        .style('font-weight', 'bold')
        .style('font-size', 14)
        .text(data[0].value.toFixed(1) + '%')

      const vsBarG = balanceG
        .append('g')
        .attr('transform', `translate(${xScale(data[1].label)}, ${margin})`)

      vsBarG
        .append('rect')
        .attr('class', 'vs-customer')
        .attr('width', xScale.bandwidth())
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('height', barHeight)
        .style('fill', '#00B729')
      vsBarG
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', -2)
        .style('fill', '#464d69')
        .style('font-weight', 'bold')
        .style('font-size', 14)
        .text(data[1].value.toFixed(1) + '%')
      vsBarG
        .append('rect')
        .attr('class', 'vs-restaurant')
        .attr('width', xScale.bandwidth())
        .attr('y', yScale(data[0].value))
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('height', barHeight - yScale(data[0].value))
        .style('fill', '#2196F3')

      const customerBarG = balanceG
        .append('g')
        .attr('transform', `translate(${xScale(data[2].label)}, ${margin})`)

      customerBarG
        .append('rect')
        .attr('class', 'customer-bar')
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('width', xScale.bandwidth())
        .attr('y', yScale(data[2].value))
        .attr('height', barHeight - yScale(data[2].value))
        .style('fill', '#00B729')
      customerBarG
        .append('text')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', yScale(data[2].value) - 2)
        .attr('text-anchor', 'middle')
        .style('fill', '#464d69')
        .style('font-weight', 'bold')
        .style('font-size', 14)
        .text(data[2].value.toFixed(1) + '%')
    }
  }
}
</script>
<style scoped>
.deactive {
  color: lightgrey;
}
.active {
  color: black;
}
</style>
